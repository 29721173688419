import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide23/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide23/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide23/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide23/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide23/image/silder3.png"

const title = (
  <>
    <h1 style={{ display: "none" }}>
      Guía para implementar la automatización de procesos digitales
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Automatizar los procesos digitales como la respuesta de preguntas
      frecuentes en canales de chat, es muy importante no solo para mejorar la
      productividad de los agentes, sino también para aumentar los niveles de
      satisfacción del cliente.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La implementación de la automatización de procesos digitales es una
    oportunidad para marcar la diferencia frente a tus competidores. Por eso, es
    necesario crear un ambiente propicio para el cambio, involucrando a tu
    equipo en todo el proceso y superando posibles resistencias.
    <br /> <br />
    Además, es necesario contar con la tecnología adecuada para una correcta
    automatización. Pero, sobre todo, que se pueda adaptar a todos los objetivos
    de cambio que tenga tu empresa.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al Cliente ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Utiliza respuestas preconfiguradas.",
      },
      {
        key: 2,
        text: "Ofrece soporte con chatbot.",
      },
      {
        key: 3,
        text: "Proporciona una base de conocimientos.",
      },
      {
        key: 4,
        text: "Agrega chat en vivo a tu sitio web.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
